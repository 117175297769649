import { clone, isSame } from "~/helpers/object";
import type { FeatureFlag, FeatureFlagValue } from "~/types/feature-flags";

export const FEATURE_FLAG_ADVANCED_VALUE = {
  superAdmins: false,
  teamOwners: false,
  teamAdmins: false,
  teamCoaches: false,
};

function formatFeatureName(feature: string) {
  return feature
    .replace("enable_", "")
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function loadFeatureFlagDetails(): Promise<
  Record<
    string,
    FeatureFlag["details"] & {
      title: string;
    }
  >
> {
  return $fetch("/json/feature-details.json");
}

async function loadFeatureFlags(): Promise<FeatureFlag[]> {
  const featuresData = await getFeatureFlags(); 
  const details = await loadFeatureFlagDetails();

  const features = Object.entries(featuresData).map(([feature, value]) => {
    const detailsForFeature = details[feature];
    const title = detailsForFeature?.title || formatFeatureName(feature);

    const item: FeatureFlag = {
      key: feature,
      label: title,
      enabled: value,
    };

    if (detailsForFeature) {
      item.details = {
        image: detailsForFeature.image,
        description: detailsForFeature.description,
      };
    }

    return item;
  });

  return features;
}

export default function useFeatureFlags() {
  const user = useUser();
  const isSuperAdmin = computed(() => user.value?.hasSuperAdminRole);
  const data = ref<null | FeatureFlag[]>(null);
  const initialData = ref<null | FeatureFlag[]>(null);
  const route = useRoute();
  const featurePageEnabled = !!isSuperAdmin.value && !!route.query.demo;

  const asyncParams = useAsyncState(loadFeatureFlags, null, {
    immediate: true,
    shallow: true,
    onSuccess: (response) => {
      data.value = clone(response);
      initialData.value = clone(response);
    },
  });
  const isDataChanged = computed(() => !isSame(initialData.value, data.value));

  async function save (payload: FeatureFlag[]) {
    const data = payload.reduce((acc, curr) => {
      acc[curr.key] = curr.enabled;
      return acc;
    }, {} as Record<string, FeatureFlagValue>);


    await updateFeatureFlags(data);

    initialData.value = payload;

    return payload;
  }

  return {
    ...asyncParams,
    data,
    initialData,
    isDataChanged,
    isPageEnabled: featurePageEnabled,
    save,
  };
}
