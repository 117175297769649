import appConfig from "~/app.config";
import formatDirectusConfig from "./format-directus-configs";

export async function updateAppConfigHandler() {
    const runtimeConfig = useRuntimeConfig();

    if (runtimeConfig.public.directus.token) {
        const directusConfig = await getCachedFeatureFlags();

        appConfig.features = formatDirectusConfig(appConfig.features, directusConfig);
        appConfig.featureRules = directusConfig.feature_rules || [];
    }
} 