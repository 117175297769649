<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import type { PxlIcon, PxlIconName } from "~/components/U/Icon";
import { iconBind } from "~/components/U/Icon";

const colors = {
  blue: "u-alert-blue",
  green: "u-alert-green",
  red: "u-alert-red",
};
const color_icons_map: Record<NonNullable<keyof typeof colors>, PxlIconName> = {
  blue: "info",
  green: "check",
  red: "warning",
};

const emit = defineEmits(["close"]);
const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    closable?: boolean;
    icon?: PxlIcon;
    color?: keyof typeof colors;
  }>(),
  {
    color: "blue",
  },
);
const alertClass = computed(() => twJoin("u-alert", colors[props.color], props.description ? "py-4" : "py-2.5"));
const alertIcon = computed(() => props.icon || color_icons_map[props.color]);
</script>

<template>
  <div :class="alertClass">
    <div class="self-stretch justify-start items-center gap-3 inline-flex">
      <UIcon
        v-if="alertIcon"
        v-bind="iconBind(alertIcon, { class: 'text-base' })"
      />
      <div
        class="grow shrink basis-0 text-sm font-bold leading-[1.188rem] whitespace-pre-wrap"
        :class="props.title ? 'font-bold' : 'font-medium'"
      >
        {{ props.title || props.description }}
      </div>
      <div class="w-4 h-4 justify-center items-center flex">
        <UButton
          v-if="props.closable"
          title="Close"
          :padded="false"
          variant="clean"
          icon="close"
          @click="emit('close')"
        />
      </div>
    </div>
    <div
      v-if="props.description && props.title"
      class="self-stretch px-7 justify-start items-start inline-flex"
    >
      <div class="grow shrink basis-0 text-sm font-medium leading-[1.188rem] whitespace-pre-wrap">
        {{ props.description }}
      </div>
    </div>
  </div>
</template>
