export default function getSharedHeaders(options?: { accessToken?: string; locale?: string }) {
    const headers: Record<string, string> = {};

    if (options?.accessToken) {
        headers["X-Shared-Authorization"] = `Bearer ${options.accessToken}`;
    }

    if (options?.locale) {
        headers["X-Request-Language"] = options.locale;
    }

    return headers;
}