<script setup lang="ts">
import type { VideoJsPlayer as IPixellotPlayer, IVideoTrimAPI } from "@pixellot/web-sdk";
import type { IPlayerState } from "../util/state";
import { ref, onMounted, onBeforeUnmount } from "vue";

const props = defineProps<{
  player: IPixellotPlayer;
  playerState: IPlayerState;
}>();

const API = ref<IVideoTrimAPI | null>(null);
const root = ref<HTMLElement | null>(null);
const selectedRange = ref<readonly [number, number]>();
const resizeObserver = ref<ResizeObserver | null>(null);
const isWorking = ref(false);

function updateMaskOffset() {
  const slider = API.value?.getSlider();

  if (!slider) return;

  // @ts-expect-error Need NoUISlider types here
  const [start, end] = slider.getPositions();
  const containerEl: HTMLDivElement | null = document.querySelector(".pxl-video-clipping .noUi-connects");

  if (!containerEl) return;

  containerEl?.style.setProperty("--mask-offset-left", start + "%");
  containerEl?.style.setProperty("--mask-offset-right", end + "%");
}

/**
 * Returns the width that each frame should have (subtract 2 to account for the first and last pips)
 * @param numberOfPips The number of pips on the slider
 */
function getFrameWidth(numberOfPips: number): number {
  return root.value && resizeObserver.value ? getContentWidth(root.value as HTMLElement) / (numberOfPips - 2) : 130;
}

/**
 * Returns the 'content' width of an element
 * @param element The element to get the content width of
 */
function getContentWidth(element: HTMLElement): number {
  const styles = window.getComputedStyle(element);

  return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
}

function renderFrames() {
  // @ts-expect-error 'state' is not defined in type of videojs.Plugin
  const thumbOptions = props.player.spriteThumbnails().state.options;
  const rows = thumbOptions.rows as number;
  const cells = thumbOptions.cells as number;
  const pips = document.querySelectorAll<HTMLDivElement>(".pxl-video-clipping .noUi-value");
  const frameWidth = getFrameWidth(pips.length);

  // the pips start off with lots set as "0:00" which makes a a poor 'working' state,
  // so we check if all pips are "0:00" and if so, we set the working state to true. this will hide the pips
  isWorking.value = Array.from(pips).filter(p => p.textContent?.trim() !== "0:00").length === 0;

  if (isWorking.value) return;

  // we need to wait for the next frame to apply the styles, so it can be laid out correctly.
  // without this we were seeing the last frame resized incorrectly.
  window.requestAnimationFrame(() => {
    pips.forEach((p, index, arr) => {
      const timestamp = Number(p.getAttribute("data-value"));
      const imgWidth = thumbOptions.width;
      const imgHeight = thumbOptions.height;

      const thumbnailIndex = Math.round(timestamp / thumbOptions.interval);
      const rowIndex = Math.floor(thumbnailIndex / cells);
      const cellIndex = thumbnailIndex % cells;
      const bgSize = `${imgWidth * cells}px ${imgHeight * rows}px`;

      const offsetTop = rowIndex * imgHeight;
      const offsetLeft = cellIndex * imgWidth;

      p.style.setProperty("--pip-width", getComputedStyle(p).width);
      p.style.setProperty("--frame-width", `${frameWidth}px`);
      p.style.setProperty("--frame-content", "''");
      p.style.setProperty("--frame-background-image", `url(${thumbOptions.url})`);
      p.style.setProperty("--frame-background-size", bgSize);
      p.style.setProperty("--frame-background-position", `-${offsetLeft}px -${offsetTop}px`);

      // Add first-frame class to the first pip to apply styling to the first frame
      if (index === 0) {
        p.classList.add("first-frame");
      }

      // if the last pip is not visible, we add a class to the previous pip to apply styling to the last frame
      if (index === arr.length - 1 && p.style.left === "100%") {
        // we don't want to show the thumbnail for the last frame if it is overflow outside the slider
        p.classList.add("no-frame");

        // figure out how much space is left for the last frame and update the `--frame-width` variable
        // we do this so we can apply a rounded border to the last frame
        const pipLeft = parseFloat(window.getComputedStyle(p).left);
        const previousPipLeft = parseFloat(window.getComputedStyle(arr[index - 1]).left);

        pips[index - 1].style.setProperty("--frame-width", `${pipLeft - previousPipLeft}px`);
        pips[index - 1].classList.add("last-frame");
      }
    });
  });
}

/**
 * Synchronizes the inner slider values with the player slider values
 */
function onInnerTrimChanged() {
  if (!API.value) return;

  selectedRange.value = API.value.getRange();

  updateMaskOffset();
}

/**
 * Synchronizes the player slider values with the inner slider values
 */
function onPlayerTrimChanged() {
  if (!API.value) return;

  reloadInnerTrim();
}

function reloadInnerTrim() {
  if (!root.value) return;

  if (API.value) {
    API.value.destroy();
    API.value = null;
  }

  const [playerStart, playerEnd] = props.player.trimAPI.getRange();

  API.value = props.player.createAPI({
    sliderRange: { min: playerStart, max: playerEnd },
    rootElement: root.value as HTMLElement,
    showPips: true,
    onChanged: onInnerTrimChanged,
  });

  API.value.show();

  renderFrames();
  onInnerTrimChanged();
}

function setRange(start: number, end: number) {
  API.value?.setRange(start, end);
}

/**
 * Since this component introduces another slider
 * we're reloading the existing timeline slider with the new options
 * so both sliders will be synchronized and work in tandem
 */
function reloadPlayerTrimAPI() {
  let playerSliderStart;
  if (props.player.duration() <= 120) {
    playerSliderStart = [0, props.player.duration()] as [number, number];
  }

  props.player.trimAPI.updateOptions({
    sliderStart: playerSliderStart,
    sliderBehaviour: "drag-fixed",
  });
}

/**
 * Creates a ResizeObserver for the given element which will call renderFrames on resize
 * @param element The element to observe for resize
 */
function initResizeObserver(element: HTMLElement) {
  destroyResizeObserver();

  if (window.ResizeObserver) {
    resizeObserver.value = new ResizeObserver(() => {
      renderFrames();
    });

    resizeObserver.value.observe(element);
  }
}

/**
 * Disconnects the ResizeObserver if it exists
 */
function destroyResizeObserver() {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
    resizeObserver.value = null;
  }
}

onMounted(() => {
  reloadPlayerTrimAPI();
  reloadInnerTrim();

  props.player.one("loadedmetadata", () => {
    reloadPlayerTrimAPI();
    reloadInnerTrim();
  });

  props.player.trimAPI.updateOptions({
    createRangeIndicators: false,
  });

  props.player.on("trim:changed", onPlayerTrimChanged);

  if (root.value) {
    initResizeObserver(root.value as HTMLElement);
  }
});

onBeforeUnmount(() => {
  props.player.off("trim:changed", onPlayerTrimChanged);
  API.value?.destroy();
  destroyResizeObserver();
});

defineExpose({ selectedRange, setRange });
</script>

<template>
  <div
    ref="root"
    class="pxl-video-clipping"
    :class="{ working: isWorking }"
  />
</template>

<style>
.pxl-video-clipping {
  padding: 40px 24px 0;
  overflow: hidden;
}

.pxl-video-clipping.working .noUi-pips-horizontal .noUi-value,
.pxl-video-clipping.working .noUi-pips-horizontal .noUi-marker {
    display: none;
}

.pxl-video-clipping .noUi-base,
.pxl-video-clipping .noUi-connects {
  width: 100%;
}

.pxl-video-clipping .noUi-horizontal {
  height: 64px;
  border: none;
  box-shadow: unset;
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::before,
.pxl-video-clipping .noUi-horizontal .noUi-connects::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::before {
  left: 0;
  width: var(--mask-offset-left);
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::after {
  right: 0;
  width: calc(100% - var(--mask-offset-right));
}

.pxl-video-clipping .noUi-horizontal .noUi-connect {
  background-color: transparent;
  border: 2px solid var(--vjs-theme-pxl--primary);
}

.pxl-video-clipping .noUi-horizontal .noUi-handle {
  height: 64px;
  width: 16px;
  top: 0;
  right: -8px;
  border: none;
  box-shadow: unset;
  background: var(--vjs-theme-pxl--primary);
}

.pxl-video-clipping .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -8px;
  right: auto;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle::before {
  height: 24px;
  width: 2px;
  border-radius: 1px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pxl-video-clipping .noUi-horizontal .noUi-handle::after {
  display: none;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle.noUi-handle-lower {
  border-radius: 4px 0px 0px 4px;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle.noUi-handle-upper {
  border-radius: 0px 4px 4px 0px;
}

.pxl-video-clipping .noUi-pips-horizontal {
  top: -40px;
  height: 115px;
  border-radius: 4px;
  padding: 0;
}

.pxl-video-clipping .noUi-value::before {
  content: var(--frame-content);
  position: absolute;
  top: 28px;
  left: calc(var(--pip-width) / 2); /* reset the thumbnail's position to the centre of the pip's timestamp text */
  width: var(--frame-width);
  height: 64px;
  background: #000;
  /* border: 1px solid red; */
  background-image: var(--frame-background-image);
  background-size: var(--frame-background-size);
  background-position: var(--frame-background-position);
}
.pxl-video-clipping .noUi-value.no-frame::before {
    display: none;
}
.pxl-video-clipping .noUi-value.first-frame::before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pxl-video-clipping .noUi-value.last-frame::before {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>
