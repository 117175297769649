<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import type { PxlIconName } from "#build/pxl-icons";

const props = withDefaults(
  defineProps<{
    name?: string;
    label?: string;
    description?: string;
    help?: string;
    hint?: string;
    error?: string | boolean;
    required?: boolean;
    disabled?: boolean;
    inline?: boolean;
    containerClass?: string;
    size?: "sm" | "md" | "lg";
    icon?: PxlIconName;
  }>(),
  {
    error: undefined,
  },
);
const formErrors = inject<Ref<IFormError[]> | null>("form-errors", null);

const inputId = computed(() => props.name);
const error = computed(() => {
  return (props.error && typeof props.error === "string") || typeof props.error === "boolean"
    ? props.error
    : formErrors?.value?.find((error) => error.path === props.name)?.message;
});

provide<IFormGroup>("form-group", {
  error,
  name: computed(() => props.name),
  size: computed(() => props.size),
  disabled: computed(() => props.disabled),
});
</script>

<template>
  <div class="u-form-group" :class="inline && 'u-form-group-inline'">
    <div v-if="label && props.icon" class="flex content-center items-center justify-between">
      <label :for="inputId" class="flex justify-between w-full">
        <p
          :class="[
            'u-label-text',
            props.required ? 'u-label-text--required' : '',
            props.disabled ? 'u-label-text--disabled' : '',
          ]"
        >
          {{ label }}
        </p>
      </label>
      
      <UIcon :name="props.icon" />
    </div>
    <div v-else-if="label" class="flex content-center items-center justify-between">
      <label :for="inputId">
        <p
          :class="[
            'u-label-text',
            props.required ? 'u-label-text--required' : '',
            props.disabled ? 'u-label-text--disabled' : '',
          ]"
        >
          {{ label }}
        </p>
      </label>
      <span v-if="hint" class="u-label-text">
        {{ hint }}
      </span>
    </div>

    <p v-if="description" class="u-label-text">
      {{ description }}
    </p>

    <div :class="twMerge([label ? inline ? 'relative' : 'mt-2 relative' : '', containerClass])">
      <slot v-bind="{ error }" />
    </div>

    <!-- prettier-ignore -->
    <p v-if="error && (typeof error !== 'boolean')" class="u-error-text">
      {{ error }}
    </p>
    <p v-if="help || $slots.help" class="mt-2 u-help-text">
      <slot name="help" />
      {{ help }}
    </p>
  </div>
</template>

<style lang="scss">
.u-form-group-inline {
  @apply inline-flex items-center gap-0;
  .u-label-text {
    @apply text-sm;
  }
}
</style>