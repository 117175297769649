import type {
  IResponseClub,
  IResponseClubMemberships,
  IResponseClubs,
  IResponseUserClubMembership,
  IResponseUserClubs,
  ITeam,
  ITeamMember,
  ITeamPayload,
  ITeamUserRole,
  TSportType,
  ITeamAthleteItem,
  IResponseTeamAthleteData,
  IUnofficialTeamPayload,
} from "~/types";
import { TEAM_USER_ROLE } from "~/constants";
import formatTeam, {
  formatTeamCreatePayload,
  formatTeamMembersData,
  formatTeamToClubPayload,
  formatTeamsData,
  formatAthleteRosterItem,
} from "~/helpers/format-team";

interface IRequestParams {
  q: string;
  limit: number;
  offset: number;
  sport_type: string;
  season: number;
  location: string;
  gender?: IResponseClub["attributes"]["gender"];
  include?: "memberships";
  sportType?: TSportType;
  relationships?: string;
  role?: string;
  country?: string;
  state?: string;
  level?: string;
}

export const getCachedTeamsList = useMemoize(
  (query: Partial<IRequestParams>): Promise<{ data: ITeam[]; total: number }> => getTeamsList(query),
);

export function getTeamsList(query: Partial<IRequestParams>): Promise<{ data: ITeam[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<IResponseClubs>("/mocks/data/clubs.json").then((res) => ({
      data: formatTeamsData(res.data, res.included),
      total: res.meta.total,
    }));

  return useAPI<IResponseClubs>("/api/v1/clubs", { query }).then((res) => ({
    data: formatTeamsData(res.data, res.included),
    total: res.meta.total,
  }));
}

export const getCachedUserTeamsList = useMemoize(
  (query: Partial<IRequestParams>): Promise<{ data: ITeam[]; total: number }> => getUserTeamsList(query),
);

export function getUserTeamsList(query: Partial<IRequestParams>): Promise<{ data: ITeam[]; total: number }> {
  const user = useUser();
  return useAPI<IResponseUserClubs>("/api/v2/users/me/clubs", { query }).then((res) => ({
    data: res.data.map((team) => {
      const formattedTeam = formatTeam(team);
      if (user.value && !user.value.teamsSports.includes(formattedTeam.sport_type)) {
        user.value.teamsSports.push(formattedTeam.sport_type);
      }

      return formattedTeam;
    }),
    total: res.meta.total,
  }));
}

export const getCachedUserTeamById = useMemoize((teamId: string, options?: { accessToken?: string }) =>
  getUserTeamById(teamId, options),
);

export function getUserTeamById(teamId: string, options?: { accessToken?: string }): Promise<ITeam | null> {
  return $atlitFetch("/v1/clubs/{ID}", {
    path: {
      ID: teamId,
    },
    query: {
      include: "memberships",
      // @ts-expect-error Parsing issue
      relationships: "breakdowns,organizations",
    },
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
  }).then((res) => {
    // @ts-expect-error ...
    return formatTeam(res.data, res.included && res.included[0]);
  });
}

export function createTeam(payload: ITeamPayload | IUnofficialTeamPayload): Promise<ITeam> {
  const body = formatTeamCreatePayload(payload);

  return useAPI<{ data: IResponseClub }>("/api/v2/clubs/", { method: "POST", body }).then((res) => {
    getCachedUserTeamsList.clear();

    return formatTeam(res.data);
  });
}

export function updateUserTeamById(teamId: string, teamData: Partial<ITeamPayload>): Promise<ITeam> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve(teamData as ITeam);

  const payload = formatTeamToClubPayload(teamData);

  return useAPI<{ data: IResponseClub }>(`/api/v1/clubs/${teamId}`, { body: payload, method: "PATCH" }).then((res) => {
    getCachedUserTeamsList.clear();
    getCachedUserTeamById.delete(teamId);

    return formatTeam(res.data);
  });
}

export function deleteUserTeamById(teamId: string): Promise<void> {
  return useAPI(`/api/v1/clubs/${teamId}`, { method: "DELETE" }).then(() => {
    getCachedUserTeamsList.clear();
    getCachedUserTeamById.delete(teamId);
  });
}

function excludeAthleteMember(member: ITeamMember): boolean {
  return member.user_role !== TEAM_USER_ROLE.ATHLETE;
}

export function getUserTeamMembersById(
  teamId: string,
  query?: Partial<IRequestParams>,
  options?: {
    excludeAthletes?: boolean;
  },
): Promise<{ data: ITeamMember[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) {
    return $fetch<IResponseClubMemberships>("/mocks/data/club-members.json").then((res) => {
      const members = formatTeamMembersData(res);
      return { data: options?.excludeAthletes ? members.filter(excludeAthleteMember) : members, total: members.length };
    });
  }

  return useAPI<IResponseClubMemberships>(`/api/v2/clubs/${teamId}/memberships`, {
    query: { ...query, include: "user" },
  }).then((res) => {
    const members = formatTeamMembersData(res);
    return { data: options?.excludeAthletes ? members.filter(excludeAthleteMember) : members, total: res.meta.total };
  });
}

export function leaveTeamById(teamId: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Team leaved successfully");

  return useAPI(`/api/v1/users/me/clubs/${teamId}`, { method: "DELETE" });
}

export function joinTeamById(teamId: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Join team request sent successfully");

  return useAPI(`/api/v1/clubs/${teamId}/requests`, { method: "POST" });
}

export function approveTeamMemberById(teamId: string, membershipId: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Team member join request was approved");

  return useAPI(`/api/v1/clubs/${teamId}/requests/${membershipId}/approve`, { method: "POST" });
}

export function rejectTeamMemberById(teamId: string, membershipId: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Team member join request was rejected");

  return useAPI(`/api/v1/clubs/${teamId}/requests/${membershipId}/reject`, { method: "POST" });
}

export function removeTeamMemberById(teamId: string, userId: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Team member was removed from the team");

  return useAPI(`/api/v1/users/me/clubs/${teamId}/users/${userId}`, { method: "DELETE" });
}

export function setTeamMemberRoleById(
  teamId: string,
  membershipId: string,
  userRole: ITeamUserRole,
): Promise<{ data: IResponseUserClubMembership }> {
  const role = userRole === TEAM_USER_ROLE.ADMIN ? "admin" : userRole === TEAM_USER_ROLE.COACH ? "coach" : "viewer";

  return useAPI(`/api/v1/clubs/${teamId}/memberships/${membershipId}`, { method: "PATCH", body: { role } });
}
/**
 * Since the BE is currently doing 2 things at once:
 * - generates the invitation token for the team
 * - sends the invitations to the given emails(if such provided)
 *
 * This function will serve the both above scenarios, but will be called by two other more specific functions.
 * So in case if BE will change and we will have two separate requests - this will die.
 */
function getTeamInviteTokenAndInviteMembers(
  teamId: string,
  options: {
    role: ITeamUserRole;
    emails?: string[];
    url?: string;
  },
): Promise<string> {
  const { role, emails, url } = options;
  const appConfig = useAppConfig();
  const INVITE_ROLES: ITeamUserRole[] = [
    TEAM_USER_ROLE.ADMIN,
    TEAM_USER_ROLE.COACH,
    TEAM_USER_ROLE.ATHLETE,
    TEAM_USER_ROLE.MEMBER,
  ];

  if (!INVITE_ROLES.includes(role))
    return Promise.reject(new Error(`Generation of invite url for a role of "${role}" is not supported yet!`));

  if (appConfig.useMockData) return Promise.resolve(btoa(JSON.stringify({ role })));

  const MEMBERSHIP_ROLE: Partial<Record<ITeamUserRole, string>> = {
    [TEAM_USER_ROLE.ADMIN]: "admin",
    [TEAM_USER_ROLE.COACH]: "coach",
    [TEAM_USER_ROLE.ATHLETE]: "athlete",
    [TEAM_USER_ROLE.MEMBER]: "viewer",
  } as const;
  const body = { role: MEMBERSHIP_ROLE[role], notifications: emails, url };

  return useAPI<{ data: { token: string } }>(`/api/v1/clubs/${teamId}/invitations`, { method: "POST", body }).then(
    (response) => response.data.token,
  );
}

export function generateTeamInviteUrl(teamId: string, role: ITeamUserRole, token?: string) {
  const baseURL = useRuntimeConfig().public.appBaseURL;
  const url = `${baseURL}/auth/invite/team?teamId=${teamId}&role=${role}&inviteToken={{TOKEN}}`;

  if (token) return url.replace("{{TOKEN}}", token);

  return url;
}

export async function getTeamInviteURL(teamId: string, role: ITeamUserRole, teamData: ITeam): Promise<string> {
  const sharedURL = useState(`invite-team-url-${role}-${teamId}`, () => "");

  // Return the url from cache if such
  if (sharedURL.value) return sharedURL.value;

  const invitationToken = await getTeamInviteTokenAndInviteMembers(teamId, { role });
  const url = generateTeamInviteUrl(teamId, role, invitationToken);

  const branchIO = useBranchIO();
  const linkData = {
    feature: "member-invite",
    data: {
      teamId,
      token: invitationToken,
      action: "shared_invite",
      invite_source: "team_member",
      $og_title: `Join "${teamData.name}" at Pixellot!`,
      $og_image_url: teamData.logo_url,
      $og_url: url,
      $desktop_url: url,
    },
  };

  sharedURL.value = await branchIO.link(linkData);

  return sharedURL.value;
}

export function inviteTeamMembers(teamId: string, role: ITeamUserRole, emails: string[]): Promise<string> {
  const url = generateTeamInviteUrl(teamId, role);
  return getTeamInviteTokenAndInviteMembers(teamId, { role, emails, url });
}

export function resendAthleteInvitation(teamId: string, membershipId?: string, email?: string): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Invitation successfully sent to the athlete!");

  const url = generateTeamInviteUrl(teamId, "athlete");

  return useAPI(`/api/v1/clubs/${teamId}/memberships/${membershipId}/invitations`, {
    method: "POST",
    body: { url, email },
  });
}

export function acceptInvitationForTeam(teamId: string, invitationToken: string): Promise<void> {
  const appConfig = useAppConfig();
  const token = useUserToken().value;

  if (appConfig.useMockData)
    return new Promise((_resolve, reject) => setTimeout(() => reject(new Error("test")), 2000));

  return useAPI(`/api/v1/clubs/${teamId}/invitations/accept`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Shared-Authorization": `Bearer ${invitationToken}`,
    },
  }).then(() => {
    getCachedUserTeamsList.clear();

    // Refresh user's leagues list
    // So in case if user had no leagues we will update those and show him new item in menu
    getCachedLeaguesList.clear();
    getCachedLeaguesList();
  });
}

export function getManageRostersUrl(teamId: string): Promise<string> {
  return useAPI<{ data: { link: string; expiresAt: number } }>(`/api/v1/clubs/${teamId}/rosters/auth`).then(
    (res) => res.data.link,
  );
}

export function getAthleteRoster(teamId: string, season?: number): Promise<{ data: ITeamAthleteItem[] }> {
  return $atlitFetch("/v1/clubs/{ID}/rosters/athletes", {
    path: { ID: teamId },
    query: { relationships: "membership,athlete" as "membership", season },
  }).then((data) => ({ ...data, data: (data.data as IResponseTeamAthleteData[]).map(formatAthleteRosterItem) }));
}

export function getVidswapDashboardUrl(teamId: string): Promise<string> {
  return $atlitFetch("/v1/clubs/{ID}/vidswap/auth", {
    path: { ID: teamId },
  }).then((res) => res.data?.link || "");
}

export function createAthletesBulkForTeamFromCSV(
  teamId: string,
  data: {
    season: number;
    file: File;
    headers: boolean;
    notification: string;
  },
) {
  const formData = new FormData();
  formData.append("file", data.file);
  // @ts-expect-error ...
  formData.append("seasons[0]", data.season);
  // @ts-expect-error ...
  formData.append("headers", data.headers);
  formData.append("notification", data.notification);

  return $atlitFetch("/v1/clubs/{ID}/rosters/uploads", {
    method: "POST",
    path: { ID: teamId },
    // @ts-expect-error FormData is not typed properly in the generated types
    body: formData,
  });
}
