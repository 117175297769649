<script lang="ts" setup>
import { updateAppConfigHandler } from "./helpers/update-app-config";

const route = useRoute();
const appConfig = useAppConfig();
const runtimeConfig = useRuntimeConfig();
const baseURL = runtimeConfig.app.baseURL;
const { locale: currentLocale } = useI18n();

const { script: zohoScript } = useZoho();

onMounted(() => {
  if (route.query.mvp) appConfig.useMVP = false;
});

useHead({
  // @ts-expect-error Look for htmlAttrs reactive types
  htmlAttrs: [
    {
      lang: computed(() => currentLocale.value || "en"),
      dir: computed(() => (currentLocale.value === "he" ? "rtl" : "ltr")),
    },
  ],
  charset: "utf-8",
  title: "Pixellot Play",
  titleTemplate: (title) => (title !== "Pixellot Play" ? `${title} · Pixellot` : title),
  meta: [
    { name: "description", content: "Pixellot Play web project" },
    { property: "og:image", content: "https://you.pixellot.tv/wp/wp-content/uploads/air-camera-youth-soccer-game.jpg" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@Pixellotltd" },
    { name: "twitter:creator", content: "@Pixellotltd" },
  ],
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: `${baseURL}images/logo/pixellot-logo-small.png`,
    },
  ],
  script: [zohoScript].filter(Boolean),
});

/**
 * This part loads the feature flag configurations from Directus and updates them under in app config under `features` namespace.
 */
const { isLoading } = useAsyncState(updateAppConfigHandler, null, { immediate: true });
</script>

<template>
  <div>
    <UModals />

    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <RToast />
    <VideoPlayerFloating />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700&display=swap");

html,
body,
#__nuxt {
  margin: 0;
  padding: 0;
  font-family: "Red Hat Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --vjs-theme-pxl--primary: #009bc9;
}

.font-red-hat-display {
  font-family: "Red Hat Display", sans-serif;
}
</style>
