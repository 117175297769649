<script setup lang="ts">
import type { IEvent } from "~/types";
import { UModalConfirm } from "#components";
import type { IPixellotPlayerSourceType } from "~/modules/pixellot-sdk";

const props = defineProps<{
  event: IEvent;
  type?: IPixellotPlayerSourceType;
}>();

const user = useUser();
const { t } = useI18n();
const toast = useToast();
const modal = useModal();
const segment = useSegment();
const isExportPopupShown = ref(false);
const label = computed(() =>
  props.type ? t("labels.request_export_type", { type: props.type }) : t("labels.request_export"),
);
const isAvailable = computed(() => {
  const hasPermissions = user.value && props.event.permissions.canExport;

  if (props.type) {
    const source = props.event.urls.get(props.type);
    const isMP4 = source && source.url.includes(".mp4");

    return hasPermissions && !isMP4;
  }

  return hasPermissions;
});

function onClick() {
  modal.open(UModalConfirm, {
    heading: t("pages.events.export_confirm_title"),
    description: t("pages.events.export_confirm_description"),
    icon: "export",
    variant: "confirm",
    confirmLabel: label.value,
    confirmFn: () => {
      segment.track("Export Video Started", formatTrackVideo(props.event));
      return requestExport(props.event.id, { stream: props.type })
        .then(() => {
          toast.success("Please come back later and the download will be available.", "Export requested successfully");
          isExportPopupShown.value = false;
        })
        .catch(onError);
    },
  });
}

function onError() {
  segment.track("Export Video Failed", formatTrackVideo(props.event));
  toast.error("Error occurred while exporting");
}
</script>

<template>
  <UListItem
    v-if="isAvailable"
    icon="export"
    :text="label"
    :on-click="onClick"
  />
</template>
