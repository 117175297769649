import type { DirectusRonaldoConfig } from "~/helpers/format-directus-configs";
import type { FeatureFlag, FeatureFlagValue } from "~/types/feature-flags";

export const getCachedFeatureFlags = useMemoize(() => {
  const { getSingletonItem } = useDirectusItems();

  return getSingletonItem<DirectusRonaldoConfig>({ collection: "ronaldo_general" });
});

export const getFeatureFlags = async () => {
  const runtimeConfig = useRuntimeConfig();
  
  const features = await $fetch<FeatureFlag>(
    "static/internal/play-fe-features/play-features.json",
    { baseURL: runtimeConfig.public.contentBaseUrl }
  );

  return features;
};

export const updateFeatureFlags = async (featuresBody: Record<string, FeatureFlagValue>) => {
  await useAPI("api/v1/internal/features/flags", { method: "PUT", body: JSON.stringify(featuresBody) });
};
